import { rowWrapper, listAutoSizer, row } from './VirtualizedLoadMoreListView.scss';
import classNames from 'owa-classnames';
import { flushSync } from 'owa-react-dom';
import { observer } from 'owa-mobx-react';
import { isCurrentCultureRightToLeft } from 'owa-localize';
import { useElementSizeTracker } from 'owa-react-hooks/lib/useElementSizeTracker';
import { useResizeObserver } from 'owa-react-hooks/lib/useResizeObserver';
import React from 'react';
import { VariableSizeList } from 'react-window';
import type { ListOnItemsRenderedProps } from 'react-window';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * BASELINE. Do not copy and paste!
 *	> 'lodash-es/debounce' import is restricted from being used by a pattern. Lodash can cause bundle bloat and performance problems with loaf. Use native function instead */
import debounce from 'lodash-es/debounce';
import { isFeatureEnabled } from 'owa-feature-flags';
import type { ListViewLoadingDirection } from 'owa-mail-loading-action-types';
import {
    safeRequestAnimationFrame,
    addEventTimeToSourceMapping,
    wrapForcedLayout,
} from 'owa-performance';
import endMailListLoadDatapoint from 'owa-mail-logging/lib/utils/mailListLoad/endMailListLoadDatapoint';

// PAGINATION_PREFETCH_BUFFER defines the size of the preload pagination buffer
// height as a factor of the viewport height. For example, if the viewport height
// is 1000px and the PAGINATION_PREFETCH_BUFFER is 2, then the preload buffer
// height will be 2000px. When the user scrolls within 2000px of the bottom
// of the list, props.onLoadMore will be called to begin prefetching the next
// "page" of rows. Akin to LoadMoreListView's DEFAULT_GUARD_PAGE_COUNT.
const PAGINATION_PREFETCH_BUFFER = 2;

// OVERSCAN_COUNT defines the number of rows to render above and below the visible
const OVERSCAN_COUNT = 4;

export interface LoadMoreListViewExtendedVirtualizedProps {
    // Estimated height of a row being windowed - This value is used to calculated
    // the estimated total size of a list before its items have all been measured.
    // The total size impacts user scrolling behavior. It is updated whenever new
    // items are measured.
    estimatedRowHeight: number;
    updateStartAndEndIndices: (start: number, end: number) => void;
    activeAnimationsCount: number;
    hiddenRowIndices: number[];
}

export interface VirtualizedLoadMoreListViewProps<TListProps, TSubComponentProps>
    extends LoadMoreListViewExtendedVirtualizedProps {
    itemIds: string[];
    onRenderRow: (
        itemId: string,
        itemIndex: number,
        listProps: TListProps | undefined,
        isScrolling: boolean
    ) => JSX.Element | null;
    // Gets a header element if needed to be rendered
    onRenderHeader?: (previousItemId: string | null, currentItemId: string) => JSX.Element | null;
    // Draws the loading component at the bottom while fetching more data
    loadingComponent?: JSX.Element;
    // Row wrapper div class
    rowWrapperClass?: string;
    // Draws a custom component before the rows of the list
    PreRowsComponent?: React.ComponentType<TSubComponentProps>;
    // Draws a custom component in the middle the rows of the list
    MidRowsComponent?: React.ComponentType<TSubComponentProps>;
    // Draws a custom component after the rows of the list
    PostRowsComponent?: React.ComponentType<TSubComponentProps>;
    subComponentProps?: TSubComponentProps;
    // Props related to the list container, to be passed to each item on render
    listProps?: TListProps;
    onLoadMoreRows: (loadingDirection: ListViewLoadingDirection) => void;
    isLoadRowsInProgress: boolean;
    getCanLoadMore: () => boolean;
    getCanLoadMorePrevious: () => boolean;
    onDidUpdate?: () => void;
    // Indicates that loaded range from itemids list
    currentLoadedIndex: number;
    // Indicates that loaded start index from the server items
    loadedStartIndex: number;
    onScroll?: (scrollingRegion: HTMLDivElement) => void;
    dataSourceId: string;
    focusedRowKey: string | null;
    focusedRowKeyIndex?: number;
    focusedNodeId?: string | null;

    // The row number where the MidRowsComponent should be rendered
    midRowsComponentRowNumber?: number;
    className?: string;
}

// VirtualizedLoadMoreListView renders a list of items using react-window by
// defining a renderRow callback function which is provided to the VariableSizedSize
// via props.children. This renderRow callback needs access to several
// VirtualizedLoadMoreListViewProps which change frequently (currentLoadedIndex,
// itemIds, isLoadRowsInProgress, etc.) but recreating the callback function each
// time is expensive, because react-window has to re-render all the rows.
// Instead, of prop-drilling, we can provide data to the children via React.Context
// without invalidating the VariableSizeList.
interface RenderRowContextProps<TListProps, TSubComponentProps>
    extends Pick<
        VirtualizedLoadMoreListViewProps<TListProps, TSubComponentProps>,
        | 'itemIds'
        | 'currentLoadedIndex'
        | 'loadedStartIndex'
        | 'isLoadRowsInProgress'
        | 'listProps'
        | 'loadingComponent'
        | 'rowWrapperClass'
        | 'onRenderRow'
        | 'onRenderHeader'
        | 'PreRowsComponent'
        | 'MidRowsComponent'
        | 'midRowsComponentRowNumber'
        | 'PostRowsComponent'
        | 'subComponentProps'
    > {
    rowHeightChanged: ((index: number, size: number) => void) | undefined;
}

const RenderRowContext = React.createContext<RenderRowContextProps<any, any>>({
    itemIds: [],
    currentLoadedIndex: 0,
    loadedStartIndex: 0,
    isLoadRowsInProgress: false,
    listProps: undefined,
    loadingComponent: undefined,
    onRenderRow: () => <></>,
    onRenderHeader: undefined,
    rowHeightChanged: undefined,
    PreRowsComponent: undefined,
    MidRowsComponent: undefined,
    midRowsComponentRowNumber: -1,
    PostRowsComponent: undefined,
    subComponentProps: undefined,
});

export interface VirtualizedLoadMoreListViewRef {
    getScrollRegion: () => HTMLDivElement | undefined;
    setFocus: () => void;
    scrollToIndex: (
        numberOfPreviousRowsLoaded: number,
        firstNewRowInstanceKey: string | undefined
    ) => void;
}

export default observer(
    React.forwardRef<
        VirtualizedLoadMoreListViewRef,
        VirtualizedLoadMoreListViewProps<any, any> // this shouldn't be 'any' but couldn't figure out how to do the types work
    >(function VirtualizedLoadMoreListView<TListProps, TSubComponentProps>(
        props: VirtualizedLoadMoreListViewProps<TListProps, TSubComponentProps>,
        ref: React.Ref<VirtualizedLoadMoreListViewRef>
    ) {
        const {
            className,
            currentLoadedIndex,
            estimatedRowHeight,
            getCanLoadMore,
            getCanLoadMorePrevious,
            isLoadRowsInProgress,
            onLoadMoreRows,
            onScroll,
            focusedRowKey,
            focusedRowKeyIndex,
            focusedNodeId,
            updateStartAndEndIndices,
            activeAnimationsCount,
            hiddenRowIndices,
            loadedStartIndex,
        } = props;
        const listAutoSizerRef = React.useRef<HTMLDivElement>(null);
        const listRef = React.useRef<VariableSizeList>(null);

        const scrollingRegionRef = React.useRef<HTMLDivElement | null>(null);
        const [scrollingRegion, setScrollingRegionInner] =
            /* eslint-disable-next-line owa-custom-rules/prefer-react-state-without-arrays-or-objects -- (https://aka.ms/OWALintWiki)
             * Please remove the array or object from React.useState() or leave a justification in case is not possible to do so.
             *	> It is preferable not to use arrays or objects as react state, use primitive data types, useReducer or satchel state instead, if its possible. */
            React.useState<HTMLDivElement | null>(null);

        const setScrollingRegion = React.useCallback(
            (newScrollingRegion: HTMLDivElement | null) => {
                scrollingRegionRef.current = newScrollingRegion;
                setScrollingRegionInner(newScrollingRegion);
            },
            []
        );

        // react-window List supports RTL via the style param (https://github.com/bvaughn/react-window/issues/148#issuecomment-468486300)
        const isRtl = isCurrentCultureRightToLeft();
        const rtlStyle: React.CSSProperties = React.useMemo(() => {
            return isRtl
                ? {
                      direction: 'rtl',
                  }
                : {};
        }, [isRtl]);

        const onScrollRef = React.useRef(onScroll);
        onScrollRef.current = onScroll;
        const rowHeights = React.useRef<number[]>([]);
        const pendingRowHeights = React.useRef<boolean[]>([]);
        const [listAutoSizerRect] = useElementSizeTracker(
            'VirtualizedLoadMoreListView_ET',
            listAutoSizerRef,
            { includeLayoutEffect: true }
        );

        // When the data source changes, scroll to the top of the list. This provides
        // parity with LoadMoreDataZone.resetScrollPosition usage.
        const currentDataSourceId = props.dataSourceId;
        React.useEffect(() => {
            safeRequestAnimationFrame(() => {
                endMailListLoadDatapoint(
                    'VML' /*componentRendered*/,
                    currentDataSourceId /*tableViewId*/
                );
                // If there's a row to focus in the list, we don't want to scroll
                // to the top of the list. Instead, we'll scroll to bring the
                // focused row into view.
                if (
                    isFeatureEnabled('tri-preserve-ml-selection') &&
                    focusedRowKeyIndex !== null &&
                    focusedRowKeyIndex !== undefined &&
                    focusedRowKeyIndex !== -1
                ) {
                    return;
                }
                const scrollingRegionRefVal = scrollingRegionRef.current;
                wrapForcedLayout('VirtualizedLoadMoreListView_scrollTop', () => {
                    if (scrollingRegionRefVal) {
                        /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
                         * This is baseline exception, if you edit this file you need to fix this exception.
                         *	> 'scrollTop' is restricted from being used. This property can cause performance problems by causing re-layouts. Please avoid if possible; if not, move to a requestAnimationFrame callback, and perform all DOM reads before performing any writes. */
                        scrollingRegionRefVal.scrollTop = 0;
                    }
                });
            });
        }, [currentDataSourceId]);

        React.useEffect(() => {
            if (scrollingRegion) {
                const onScrollingRegionScroll = (ev: Event) => {
                    addEventTimeToSourceMapping(ev, 'VirtualizedLoadMoreListView');
                    onScrollRef.current?.(scrollingRegion);
                };
                scrollingRegion.addEventListener('scroll', onScrollingRegionScroll);

                return function cleanup() {
                    scrollingRegion.removeEventListener('scroll', onScrollingRegionScroll);
                };
            }

            return undefined; // no cleanup needed
        }, [scrollingRegion]);

        const debounceForceUpdate = debounce(
            () => {
                listRef.current?.forceUpdate();
            },
            10,
            {
                leading: true,
                trailing: true,
            }
        );

        const rowHeightChanged = React.useCallback(
            (index: number, size: number, isBeingCollapsed: boolean = false) => {
                if (rowHeights.current[index] !== size) {
                    const wasPreviouslyHidden = rowHeights.current[index] === 0;

                    // Update the measured height for the row (that react-window
                    // will use to calculate the scroll position and item sizes)
                    rowHeights.current[index] = size;

                    // If a row was previously hidden and is now being re-rendered
                    // with a non-zero height, then it's the scenario where a previously
                    // collapsed group is expanded and we can debounce the visual
                    // update to avoid layout thrashing.
                    //
                    // We can also debounce the visual update if the row is being
                    // collapsed, as it will likely be followed by a series of
                    // other rows being collapsed and we can batch the visual
                    // update to avoid layout thrashing.
                    const shouldForceUpdateImmediately = !wasPreviouslyHidden && !isBeingCollapsed;

                    // This clears the cached row heights and forces a re-render
                    // of the list. This is necessary to ensure that the list
                    // correctly updates item sizes.
                    // Wrap it in flushSync to ensure the update happens immediately even with the fwk-createRoot
                    // flight on
                    if (isFeatureEnabled('fwk-createRoot')) {
                        flushSync(() => {
                            // Only tells the library to reset/recalculate the row height UI if these are not on the pending list
                            // The UI should be refreshed once these rows are rendered/visible, in onItemsRendered
                            if (!pendingRowHeights.current[index]) {
                                listRef.current?.resetAfterIndex(
                                    index,
                                    shouldForceUpdateImmediately
                                );
                            }

                            if (!shouldForceUpdateImmediately) {
                                debounceForceUpdate();
                            }
                        });
                    } else {
                        // Only tells the library to reset/recalculate the row height UI if these are not on the pending list
                        // The UI should be refreshed once these rows are rendered/visible, in onItemsRendered
                        if (!pendingRowHeights.current[index]) {
                            listRef.current?.resetAfterIndex(index, shouldForceUpdateImmediately);
                        }

                        if (!shouldForceUpdateImmediately) {
                            debounceForceUpdate();
                        }
                    }
                }
            },
            []
        );

        // LoadMore logic refers to data pagination. When the user scrolls near the bottom
        // of the list and there is more data available, we'll invoke props.onLoadMoreRows.
        // VariableSizeList.onItemsRendered is the callback that fires when the list is scrolled.
        // When either the currentLoadedIndex or scroll position changes
        const listVisibleRange = React.useRef<{
            start: number;
            end: number;
        }>({
            start: 0,
            end: 0,
        });

        const isScrollPerfEnabled = React.useMemo(() => isFeatureEnabled('fwk-scroll-perf'), []);
        const renderRowContextValue = React.useMemo((): RenderRowContextProps<
            TListProps,
            TSubComponentProps
        > => {
            return {
                itemIds: props.itemIds,
                currentLoadedIndex,
                loadedStartIndex,
                isLoadRowsInProgress,
                listProps: props.listProps,
                loadingComponent: props.loadingComponent,
                onRenderRow: props.onRenderRow,
                onRenderHeader: props.onRenderHeader,
                rowHeightChanged,
                PreRowsComponent: props.PreRowsComponent,
                MidRowsComponent: props.MidRowsComponent,
                midRowsComponentRowNumber: props.midRowsComponentRowNumber,
                PostRowsComponent: props.PostRowsComponent,
                subComponentProps: props.subComponentProps,
            };
        }, [
            props.itemIds,
            currentLoadedIndex,
            loadedStartIndex,
            isLoadRowsInProgress,
            props.listProps,
            props.loadingComponent,
            props.onRenderRow,
            props.onRenderHeader,
            rowHeightChanged,
            props.PreRowsComponent,
            props.MidRowsComponent,
            props.midRowsComponentRowNumber,
            props.PostRowsComponent,
            props.subComponentProps,
        ]);

        const getRowHeight = React.useCallback(
            (index: number) => {
                if (index === 0) {
                    // Not all table views have headers, so the estimatedRowHeight
                    // should account for that (i.e. be 0).
                    return rowHeights.current[0] ?? 0;
                }

                return rowHeights.current[index] ?? estimatedRowHeight;
            },
            [estimatedRowHeight]
        );

        // Used so callbacks that needs a reference, don't have to be updated on every render
        const currentLoadedIndexRef = React.useRef(currentLoadedIndex);
        currentLoadedIndexRef.current = currentLoadedIndex;

        const getNumHiddenRowsInView = React.useCallback((): number => {
            const { start, end } = listVisibleRange.current;
            let numHiddenRows = 0;
            for (const index of hiddenRowIndices) {
                if (index + 1 >= start && index + 1 <= end) {
                    numHiddenRows++;
                }
            }
            return numHiddenRows;
        }, [hiddenRowIndices]);

        // This callback is triggered whenever there is a scroll event or
        // the length of available rows (currentLoadedIndex) changes. It also gets
        // triggered on a group header collapse/expansion.
        const loadMoreRowsIfNecessary = React.useCallback(async () => {
            const { start, end } = listVisibleRange.current;
            const bufferedElements = Math.max(
                (end - start - getNumHiddenRowsInView()) * PAGINATION_PREFETCH_BUFFER,
                0
            );
            const areAllItemsAtBottomVisibleAndCanFetchNext =
                currentLoadedIndexRef.current + 1 === end && getCanLoadMore();
            const nearVisibleEnd = currentLoadedIndexRef.current - end < bufferedElements;
            const nearVisibleStart = start < bufferedElements;
            // If user seeing all the items at the bottom, gives precedence to NextPage
            // instead of PreviousPage so we can fill the screen at the bottom
            // We assume users care more about scrolling down than up
            if (
                !areAllItemsAtBottomVisibleAndCanFetchNext &&
                nearVisibleStart &&
                getCanLoadMorePrevious()
            ) {
                onLoadMoreRows('PreviousPage');
            }

            if (nearVisibleEnd && getCanLoadMore()) {
                onLoadMoreRows('NextPage');
            }
        }, [getCanLoadMore, getCanLoadMorePrevious, onLoadMoreRows, getNumHiddenRowsInView]);

        const onItemsRendered = React.useCallback(
            (onItemsRenderedProps: ListOnItemsRenderedProps) => {
                const { visibleStartIndex, visibleStopIndex } = onItemsRenderedProps;
                listVisibleRange.current = { start: visibleStartIndex, end: visibleStopIndex };
                updateStartAndEndIndices(visibleStartIndex, visibleStopIndex);

                // In case the row heights are pending, we need to reset the list to ensure the new row heights are applied
                // and UI is updated. We only need to reset the rows that are pending, so we can skip the rest
                if (pendingRowHeights.current[visibleStartIndex]) {
                    pendingRowHeights.current[visibleStartIndex] = false;
                    if (isFeatureEnabled('fwk-createRoot')) {
                        flushSync(() => {
                            listRef.current?.resetAfterIndex(visibleStartIndex, false);
                        });
                    } else {
                        listRef.current?.resetAfterIndex(visibleStartIndex, false);
                    }
                }

                loadMoreRowsIfNecessary();
            },
            [loadMoreRowsIfNecessary]
        );

        // When the number of available rows has changed, either because items
        // were added/removed from the view OR because another "onLoadMoreRows"
        // batch has completed loading and is available to render, we need
        // to recalculate whether we're near the end of the vieport or not.
        React.useEffect(() => {
            loadMoreRowsIfNecessary();
        }, [currentLoadedIndex, loadedStartIndex]);

        // We avoid loading more items in the list while there are active/pending
        // animations to prevent layout shifts while animating. Once all animations
        // have completed, we'll check if we're near the end of the viewport and
        // load more rows if necessary (as we may have prevented the load if
        // the user was near the end of the viewport and had ongoing animations
        // when the check was originally performed).
        React.useEffect(() => {
            if (activeAnimationsCount === 0) {
                loadMoreRowsIfNecessary();
            }
        }, [activeAnimationsCount]);

        React.useImperativeHandle(
            ref,
            () => ({
                getScrollRegion: () => scrollingRegionRef.current as HTMLDivElement,
                setFocus: () => scrollingRegionRef.current?.focus(),
                scrollToIndex: (
                    numberOfPreviousRowsLoaded: number,
                    firstNewRowInstanceKey: string | undefined
                ) => {
                    const clientHeight = wrapForcedLayout(
                        'VirtualizedLoadMoreListView_clientHeight',
                        /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
                         * This is baseline exception, if you edit this file you need to fix this exception.
                         *	> 'clientHeight' is restricted from being used. This property can cause performance problems by causing re-layouts. Please avoid if possible; if not, move to a requestAnimationFrame callback, and perform all DOM reads before performing any writes. */
                        () => (scrollingRegionRef.current as any)?.clientHeight // The height of the outer container of the VLV viewport
                    );
                    let cumulativeHeightOfRows = 0; // The cumulative height of the rows that have been measured already
                    let shouldScrollToIndex = false;
                    // Case user is scrolling to the top of the list, we should use the previous scrollOffset to calculate the new scroll position
                    // VariableSizeList uses a binary search to find the item to scroll with an exact match, so we ceil the value to ensure the
                    // search finds the correct item and not the previous one due to floating point precision
                    let offset = Math.ceil(
                        (listRef.current as any)?.state?.scrollOffset +
                            numberOfPreviousRowsLoaded * estimatedRowHeight
                    );

                    // Check if the existing items have a cumulative height that is greater than the viewport height. Once we hit that, then break out of the loop
                    // because we already know we will have to scroll to the appropriate index.
                    for (const rowHeight of rowHeights.current) {
                        cumulativeHeightOfRows += rowHeight;
                        if (cumulativeHeightOfRows > clientHeight) {
                            shouldScrollToIndex = true;
                            break;
                        }
                    }

                    // If the cumulative height of the existing rows is less than the viewport height, we should still add the estimated height of the new rows
                    // plus the previous scrollOffset (this is stored in the variable offset above) to cumulativeHeightOfRows to see if that value
                    // will then be greater than the viewport height. If so, we will also need to scroll to the index.
                    if (!shouldScrollToIndex) {
                        shouldScrollToIndex = cumulativeHeightOfRows + offset > clientHeight;
                        // If we don't need to scroll to the index (there is not scroll bar shown due to lack of data on the viewport), we can just return
                        if (!shouldScrollToIndex) {
                            return;
                        }
                    }

                    // Fill the beginning of the rowHeights array with the estimatedRowHeight and 0 as first index
                    // and then update the rowHeights array with the new row heights plus the values we had already calculated
                    // Previous rowHeight [0, customRowHeight, estimatedRowHeight, customRowHeight, ...]
                    // will become [0, estimatedRowHeight, estimatedRowHeight, estimatedRowHeight, ... + customRowHeight, estimatedRowHeight, customRowHeight, ...]
                    const filledArray = [
                        rowHeights.current[0],
                        ...Array(numberOfPreviousRowsLoaded).fill(estimatedRowHeight),
                    ];

                    // Mark the overscan rows as pending so that their heights are calculated but we dont refresh the UI
                    // otherwise the content will be pushed down after loading rows above since the overscan rows are rendered.
                    const firstNewRowIndex = firstNewRowInstanceKey
                        ? props.itemIds.indexOf(firstNewRowInstanceKey)
                        : -1;
                    const previousRowAtTop = numberOfPreviousRowsLoaded + 1;
                    pendingRowHeights.current = [];
                    for (
                        let i = Math.max(1, previousRowAtTop - OVERSCAN_COUNT);
                        i < previousRowAtTop;
                        i++
                    ) {
                        pendingRowHeights.current[i] = true;
                        // If we are loading previous rows and items match whatever it was already loaded
                        // we have seen that heights are not recalculated, so reuse their heights
                        // and adjust the offset accordingly
                        if (rowHeights.current[i] && firstNewRowIndex !== -1) {
                            // Update the filled array entries with non-estimated row heights and adjust the offset
                            filledArray[i] = rowHeights.current[i];
                            if (rowHeights.current[i] !== estimatedRowHeight) {
                                offset = offset - estimatedRowHeight + rowHeights.current[i];
                            }
                        }
                    }

                    // Assign the new row heights to the rowHeights array
                    rowHeights.current = filledArray.concat(rowHeights.current.slice(1));

                    // Jump to the offset calculated above
                    wrapForcedLayout('VirtualizedLoadMoreListView_scrollTo', () => {
                        /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
                         * This is baseline exception, if you edit this file you need to fix this exception.
                         *	> 'scrollTo' is restricted from being used. This property can cause performance problems by causing re-layouts. Please avoid if possible; if not, move to a requestAnimationFrame callback, and perform all DOM reads before performing any writes. */
                        listRef.current?.scrollTo(Math.ceil(offset + 1)); // Need to add extra pixel since scrollTop of the container is not matching the offset passed, so library is not able to find the exact row to scroll to
                    });

                    // We need to reset the list to ensure the new row heights are applied, to avoid caching the old row heights
                    if (isFeatureEnabled('fwk-createRoot')) {
                        flushSync(() => {
                            listRef.current?.resetAfterIndex(0, false);
                        });
                    } else {
                        listRef.current?.resetAfterIndex(0, false);
                    }
                },
            }),
            []
        );

        const scrollRowIntoView = React.useCallback((targetIndex: number) => {
            const { start, end } = listVisibleRange.current;

            // Add 1 to the targetIndex to account for the PreRowsComponent
            // that takes index 0 in the VLV.
            const indexToScrollTo = targetIndex + 1;
            if (indexToScrollTo < start || indexToScrollTo > end) {
                if (isFeatureEnabled('fwk-createRoot')) {
                    // We need to wrap this in a flushSync to force this to re-render immediately to ensure the list is scrolled to the expected position.
                    // Otherwise, while keyboard navigating down to an item out of view, we briefly see a frame painted in the interim where the list is
                    // scrolled to the default position defined by the library's component where the new item in focus is positioned at the midpoint of the list.
                    flushSync(() => {
                        listRef.current?.scrollToItem(indexToScrollTo);
                    });
                } else {
                    listRef.current?.scrollToItem(indexToScrollTo);
                }
            }
        }, []);

        // When the focusedNodeId (item part) or focusedRowKey changes, we need to scroll that row into view always.
        React.useEffect(() => {
            const { start, end } = listVisibleRange.current;
            const focusedRowIndex = focusedRowKey ? props.itemIds.indexOf(focusedRowKey) : -1;

            if (isFeatureEnabled('tri-preserve-ml-selection')) {
                safeRequestAnimationFrame(() => {
                    if (
                        focusedRowIndex !== null &&
                        focusedRowIndex !== undefined &&
                        focusedRowIndex !== -1
                    ) {
                        // If the start and end indices are both 0, the VLV is
                        // either empty or hasn't completed loading so we
                        // don't want to scroll yet. Instead, wait a tick to
                        // see if the list has loaded and then scroll. Otherwise,
                        // scroll immediately.
                        if (start === 0 && end === 0) {
                            setTimeout(() => {
                                // If the list still hasn't loaded, then we
                                // will not scroll.
                                if (
                                    listVisibleRange.current.start === 0 &&
                                    listVisibleRange.current.end === 0
                                ) {
                                    return;
                                }

                                scrollRowIntoView(focusedRowIndex);
                            }, 0);
                        } else {
                            scrollRowIntoView(focusedRowIndex);
                        }
                    }
                });
            } else {
                if (
                    focusedRowIndex !== null &&
                    focusedRowIndex !== undefined &&
                    focusedRowIndex !== -1 &&
                    !(start === 0 && end === 0) // If the start and end indices are both 0, the VLV is either empty or hasn't completed loading so we don't want to scroll.
                ) {
                    scrollRowIntoView(focusedRowIndex);
                }
            }
        }, [focusedRowKey, focusedNodeId, props.itemIds]);

        // Because PreRowsComponent and PostRowsComponent need to be contained within
        // the list, VariableSizeList.itemCount is given two extra. The component that
        // renders rows (VariableSizeRowWrapper) will internally map these indexes:
        // [0] corresponds to PreRowsComponent
        // [1, currentLoadedIndex] maps to props.onRenderRow called with index-1
        //   (to compensate for PreRowsComponent taking index 0)
        // [currentLoadedIndex + 1] to PostRowsComponent
        const listItemCount = currentLoadedIndex + 2;

        return (
            <RenderRowContext.Provider value={renderRowContextValue}>
                <div className={classNames(rowWrapper, props.rowWrapperClass)}>
                    <div ref={listAutoSizerRef} className={listAutoSizer}>
                        <VariableSizeList
                            ref={listRef}
                            outerRef={setScrollingRegion}
                            className={className}
                            width={listAutoSizerRect?.width || 0}
                            height={listAutoSizerRect?.height || 0}
                            overscanCount={OVERSCAN_COUNT}
                            onItemsRendered={onItemsRendered}
                            itemCount={listItemCount}
                            itemSize={getRowHeight}
                            estimatedItemSize={estimatedRowHeight}
                            style={rtlStyle}
                            useIsScrolling={isScrollPerfEnabled}
                        >
                            {VariableSizeRowWrapper}
                        </VariableSizeList>
                    </div>
                </div>
            </RenderRowContext.Provider>
        );
    }),
    'VirtualizedLoadMoreListView'
);

interface VariableSizeListRowWrapperProps {
    index: number;
    style: React.CSSProperties;
    isScrolling?: boolean;
}

const VariableSizeRowWrapper = observer(function VariableSizeRowWrapper(
    wrapperProps: VariableSizeListRowWrapperProps
) {
    const renderRowContext = React.useContext(RenderRowContext);
    const {
        itemIds,
        currentLoadedIndex,
        loadedStartIndex,
        isLoadRowsInProgress,
        listProps,
        loadingComponent,
        onRenderRow,
        onRenderHeader,
        rowHeightChanged,
        PreRowsComponent,
        MidRowsComponent,
        midRowsComponentRowNumber,
        PostRowsComponent,
        subComponentProps,
    } = renderRowContext;

    const renderHeaderAboveRow = React.useCallback(
        (rowToRender: number): JSX.Element | null => {
            if (onRenderHeader) {
                const previousElementId =
                    rowToRender > 0 && rowToRender <= currentLoadedIndex
                        ? itemIds[rowToRender - 1]
                        : null;
                const currentElementId = itemIds[rowToRender];
                return onRenderHeader(previousElementId, currentElementId);
            }
            return null;
        },
        [onRenderHeader, currentLoadedIndex, itemIds]
    );

    const renderRow = React.useCallback(
        (index: number): JSX.Element | null => {
            // `index` is coming from react-window's VariableSizeList and
            // needs to be mapped according to the comment in VirtualizedLoadMoreListView
            // pertaining to VariableSizeList.itemCount / listItemCount
            if (index === 0) {
                // We don't want to render the PreRowsComponent unless we are at the true
                // start of the list which can only happen when loadedStartIndex is 0.
                return PreRowsComponent && loadedStartIndex === 0 ? (
                    <PreRowsComponent {...subComponentProps} />
                ) : null;
            }
            if (index === currentLoadedIndex + 1) {
                return PostRowsComponent ? <PostRowsComponent {...subComponentProps} /> : null;
            }

            const indexOfRowToRender = index - 1;
            if (indexOfRowToRender < 0 || indexOfRowToRender >= currentLoadedIndex) {
                return null;
            }

            const header = renderHeaderAboveRow(indexOfRowToRender);
            const midRowsComponent =
                indexOfRowToRender === midRowsComponentRowNumber && MidRowsComponent ? (
                    <MidRowsComponent {...subComponentProps} />
                ) : null;

            const rowToRender = onRenderRow(
                itemIds[indexOfRowToRender],
                indexOfRowToRender,
                listProps,
                // render minimum if we are scrolling or the row is not visible
                !!wrapperProps.isScrolling
            );
            const loadingSpinnerComponent =
                indexOfRowToRender == currentLoadedIndex - 1 &&
                isLoadRowsInProgress &&
                loadingComponent;

            if (
                header ||
                midRowsComponent ||
                rowToRender /* used to be null, now <></> */ ||
                loadingSpinnerComponent
            ) {
                return (
                    <>
                        {header}
                        {midRowsComponent}
                        {rowToRender}

                        {/* The last row gets a loading spinner if we're actively loading more data */}
                        {loadingSpinnerComponent}
                    </>
                );
            } else {
                return null;
            }
        },
        [
            renderHeaderAboveRow,
            onRenderRow,
            currentLoadedIndex,
            loadedStartIndex,
            listProps,
            isLoadRowsInProgress,
            loadingComponent,
            itemIds,
            midRowsComponentRowNumber,
            PreRowsComponent,
            MidRowsComponent,
            PostRowsComponent,
            subComponentProps,
            wrapperProps.isScrolling,
        ]
    );

    return (
        <VariableSizeListRow
            {...wrapperProps}
            renderContent={renderRow}
            onRowHeightChanged={rowHeightChanged}
        />
    );
},
'VariableSizeRowWrapper');

interface VariableSizeRowProps {
    style: any;
    index: number;
    renderContent: (index: number) => JSX.Element | null;
    onRowHeightChanged:
        | ((index: number, height: number, shouldForceUpdate?: boolean) => void)
        | undefined;
}

const VariableSizeListRow = observer(function VariableSizeListRowInner(
    props: VariableSizeRowProps
) {
    const rowRef = React.useRef<HTMLDivElement>(null);
    const { onRowHeightChanged, index } = props;

    const onSizeChanged = React.useCallback(
        (rect: DOMRectReadOnly) => {
            if (rowRef.current) {
                onRowHeightChanged?.(index, rect.height);
            }
        },
        [onRowHeightChanged, index]
    );

    useResizeObserver('VirtualizedLoadMoreListView_RO', rowRef, onSizeChanged);

    const content = props.renderContent(index);
    const isBeingCollapsed = !content;

    React.useLayoutEffect(() => {
        if (isBeingCollapsed) {
            // Apparently useLayoutEffect is considered "part of rendering" by React; we want this to happen after
            // render has completed, so we put it in a microtask
            Promise.resolve().then(() => {
                onRowHeightChanged?.(index, 0, true /* isBeingCollapsed */);
            });
        }
    }, [isBeingCollapsed]);

    if (content) {
        return (
            <div style={props.style}>
                <div ref={rowRef} data-animatable={true} className={row}>
                    {content}
                </div>
            </div>
        );
    } else {
        return null;
    }
},
'VariableSizeListRowInner');
